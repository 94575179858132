import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormModal from './components/modalForm'
import axios from '../services/axios'
import SelectSearchInput from '../utils/components/selectSearchInput';
import DatePickerCustom from '../utils/components/datePicker';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}

const Index = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [devises, setDevises] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [sumDepenseByDevise, setSumDepenseByDevise] = React.useState({})
  const [redirect, setRedirect] = React.useState(false)

  const [form] = Form.useForm();

  React.useEffect(() => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    fetchData({ pagination } as IProps);
    getDevise()
    getClient()
    getSumDepenseByDevise(dateFormated, dateFormated)
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const getClient = () => {
    axios.get('/clients')
      .then(response => {
        
        setClients(response.data);
        setLoading(false)
      });
  }

  const fetchData = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');

    axios.get(`/depenses/${dateFormated}/${dateFormated}`)
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        
        setData(response.data)
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };

  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }
  const getSumDepenseByDevise = (start: any, end: any) => {
    axios.get(`/depenses/rapport/sum-depense/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumDepenseByDevise(response.data);
        setLoading(false)
      });
  }

  const onCreate = (values: any) => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');

    axios.post('/depenses/new', values)
      .then(function (response) {
        
        setLoading(false);
        fetchData(pagination)
        getSumDepenseByDevise(dateFormated, dateFormated)
        setRedirect(true)
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
    setVisible(false);
  };

  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
  }
  function onSearch(val: string) {

  }
  const handleOk = () => {
    console.log('Clicked on button');
    setConfirmLoading(true);
    setTimeout(() => {
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {

  };

  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'devise') {
      const { devise } = item
      return devise.code
    }
  };

  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'N° reçu',
      dataIndex: 'numero_recu',
      key: 'numero_recu',
    },
    {
      title: 'Date',
      dataIndex: 'date_depense',
      key: 'date_depense',
    },
    {
      title: 'Compte debité',
      dataIndex: 'compte_debite',
      key: 'compte_debite',
      render: (compte: any, record: any) => (
        <>
          {compte ? compte : record.client.prenom + " " + record.client.nom + " / " + record.client.telephone}
          { }
        </>
      ),

    },
    {
      title: 'Libelle',
      dataIndex: 'motif',
      key: 'motif',
    },
    {
      title: 'Montant',
      dataIndex: 'montant',
      alignItems: 'right',
      key: 'montant',
      render: (montant: any, record: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + record.devise.code}
          </Tag>
          { }
        </>
      ),

    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <>
          <Link style={{ marginRight: 10 }} to={`/depenses/edit/${id}`}>Editer</Link>
        </>
      )
    },

  ];
  const onDateChange = (dates: any, dateStrings: any) => {
    axios.get(`/depenses/${dateStrings[0]}/${dateStrings[1]}`)
      .then(response => {
        
        setData(response.data)
        setLoading(false)
      });
    getSumDepenseByDevise(dateStrings[0], dateStrings[1])
  }
  return <>
    <Card size="small" title={<h4>Liste des Depenses</h4>} extra={<Button
      type="primary"
      onClick={() => {
        setVisible(true);
      }}
    >
      Nouveau
    </Button>} >
      <Row justify='end'>
        <Form layout='inline'>
          <Form.Item label="Dates">
            <DatePickerCustom
              onChange={onDateChange}
            />
          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />

      <Table
        columns={columns}
        rowKey={(record: any) => record.login}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />
      <Row justify='end'>
        {
          Object.entries(sumDepenseByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }

      </Row>
      <FormModal
        visible={visible}
        onCreate={onCreate}
        devises={devises}
        clients={clients}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </Card>
    {
      redirect ? (<Redirect to="/depenses/" />) : ''
    }
  </>;
}

export default Index;