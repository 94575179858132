import { Menu, Avatar, Button } from 'antd';
import '../../assets/style.css'
import { Link } from 'react-router-dom'
import logo from './assets/logo2.png'
import { UserOutlined, ToolOutlined, RetweetOutlined,
            AppstoreOutlined, PlusSquareOutlined, MenuUnfoldOutlined,
            ArrowLeftOutlined, FullscreenOutlined, DollarCircleOutlined, 
            TransactionOutlined, ArrowRightOutlined, InteractionOutlined,
            SettingFilled, MenuFoldOutlined, FullscreenExitOutlined,
            UserSwitchOutlined, UsergroupAddOutlined, ExportOutlined, 
            SwapOutlined,VerticalAlignMiddleOutlined,DragOutlined,RotateLeftOutlined
        } 
from '@ant-design/icons';
const { SubMenu } = Menu;
export default function Index() {

    return (
        <>
            <div style={{ height: '32px', background: 'rgba(255, 255, 255, 0.2)', margin: '16px' }} className="logo" >
                <Link to="/">
                    <img src={logo} height={30} width={50} />
                    <span className='ml-2 text-primary h5'>B-Devise</span>
                </Link>
            </div>
            <Menu style={{ backgroundColor: "#3F0E40"}} theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                <SubMenu  style={{ backgroundColor: "#3F0E40"}} key="sub1" icon={<UsergroupAddOutlined />} title="Opérations Client">
                    <Menu.Item key="1" icon={<UserSwitchOutlined />}>
                        <Link to="/clients">Clients</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<InteractionOutlined />}>
                        <Link to="/retrait">Rétrait</Link>
                    </Menu.Item>
                    <Menu.Item key="333" icon={<InteractionOutlined />}>
                        <Link to="/retrait/valide">Valider Retrait</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<ExportOutlined />}>
                        <Link to="/depot">Dépot</Link>
                    </Menu.Item>
                    <Menu.Item key="33" icon={<ExportOutlined />}>
                        <Link to="/depot/valide">Valider Depot</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="9" icon={<VideoCameraOutlined />}>
                        <Link to="/compte-client">Compte Client</Link>
                    </Menu.Item> */}
                </SubMenu>

                <SubMenu key="sub2" icon={<FullscreenExitOutlined />} title="Factures Entrée">
                    <Menu.Item key="4" icon={< ArrowRightOutlined />}>
                        <Link to="/factures/entrees">Entrées</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={< MenuFoldOutlined />}>
                        <Link to="/sorties/valide">valider Sorties</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub22" icon={<FullscreenOutlined />} title="Factures Sortie">
                    <Menu.Item key="44" icon={<ArrowLeftOutlined />}>
                        <Link to="/factures/sorties">Sorties</Link>
                    </Menu.Item>
                    <Menu.Item key="55" icon={<MenuUnfoldOutlined />}>
                        <Link to="/entrees/valide">valider Entrées</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub4" icon={<RetweetOutlined />} title="Rapports">
                    <Menu.Item key="11" icon={<RotateLeftOutlined />}>
                        <Link to="/rapports/credits">Crédits</Link>
                    </Menu.Item>
                    <Menu.Item key="113" icon={<SwapOutlined />}>
                        <Link to="/rapports/clients/in-bureau">Entrée/sortie</Link>
                    </Menu.Item>
                    <Menu.Item key="112" icon={<DragOutlined />}>
                        <Link to="/rapports/clients/hors-bureau">Hors bureau</Link>
                    </Menu.Item>
                    <Menu.Item key="1123" icon={<VerticalAlignMiddleOutlined />}>
                        <Link to="/rapports/all-transations/by-client">Transation / client</Link>
                    </Menu.Item>
                    <Menu.Item key="111" icon={<DollarCircleOutlined />}>
                        <Link to="/rapports/soldes-bureau">Soldes Bureau</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" icon={<SettingFilled />} title="Parametres">
                    <Menu.Item key="6" icon={<UserOutlined />}>
                        <Link to="/utilisateurs">Utilisateurs</Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<TransactionOutlined />}>
                        <Link to="/devises">Devises</Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<ToolOutlined />}>
                        <Link to="/taux-jours">Taux du jour</Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<AppstoreOutlined />}>
                        <Link to="/depenses">Depenses</Link>
                    </Menu.Item>
                    <Menu.Item key="99" icon={<PlusSquareOutlined />}>
                        <Link to="/inject-fond">Injecter fond</Link>
                    </Menu.Item>

                    <Menu.Item key="990" icon={<PlusSquareOutlined />}>
                        <Link to="/depenses/validate/depense-inject">Valider </Link>
                    </Menu.Item>

                    <Menu.Item key="10" icon={<DollarCircleOutlined />}>
                        <Link to="/comptes-globales">Soldes</Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </>
    );
}