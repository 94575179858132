import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import Logo from './assets/company.png'
import Compagny from './assets/logo.png'
import Forms from './components/form'

interface IProps {
    onSubmit: (values: any) => void,
    isLoading: boolean,
    isRedirect?: boolean
}
export default function Index({ onSubmit, isLoading }: IProps) {

    const [isRedirect, setRedirect] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const history = useHistory()
    const onFinish = (values: any) => {
        setLoading(true)
        onSubmit(values)
    };

    return (
        <div className="content">
            <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                <div className="card card0 border-0">
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="card1 pb-5">
                                <div className="row"> <img src={Logo} className="logo" /> </div>
                                <div className="row px-3 justify-content-center mt-4 mb-5 border-line">
                                    <img src={Logo} className="image" /> </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div  className="card2 card border-0 px-4 py-5">
                                <div className="row mb-4 px-3">
                                    <h6 className="mb-0 mr-0 mt-2"></h6>
                                    <div style={{ backgroundColor: "#3F0E40"}}  className="facebook text-center mr-3">
                                        <div className="fa fa-facebook"></div>
                                    </div>
                                    <div style={{ backgroundColor: "#155491"}}  className="twitter text-center mr-3">
                                        <div className="fa fa-twitter"></div>
                                    </div>
                                    <div style={{ backgroundColor: "#208938"}}  className="linkedin text-center mr-3">
                                        <div className="fa fa-linkedin"></div>
                                    </div>
                                </div>
                                <div className="row px-3 mb-0">
                                    <div className="line"></div> <span className="or text-center">Authentification</span>
                                    <div className="line"></div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: 'Please input your Username!' }]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Nom d'utilisateur" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your Password!' }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Mot de passe"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item name="remember" valuePropName="checked" noStyle>
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item>

                                    </Form.Item>

                                    <Form.Item>
                                        <Button style={{ backgroundColor: "#155491"}} loading={isLoading} type="primary" htmlType="submit" className="login-form-button">
                                            Se connecter
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="bg-blue py-4">
                        <div className="row px-3"> <small className="ml-4 ml-sm-5 mb-2">Copyright &copy; 2022. All rights reserved.</small>
                            <div className="social-contact ml-4 ml-sm-auto"> <span className="fa fa-facebook mr-4 text-sm"></span> <span className="fa fa-google-plus mr-4 text-sm"></span> <span className="fa fa-linkedin mr-4 text-sm"></span> <span className="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isRedirect && history.push({ pathname: '/', state: { isAuth: true } })
            }
        </div>
    );
}
