import axios from 'axios';
import { BASE_URL_API } from '../../utils/constants';
const token = null;
// axios.defaults.baseURL = 'https://a.ets-dms-senke.com/api/'
axios.defaults.baseURL = `${BASE_URL_API}/api/`
axios.defaults.headers.common = {
    'Authorization': `Bearer ${token}`,
    // 'X-Requested-With': 'XMLHttpRequest',
    // // 'Access-Control-Request-Headers': 'x-requested-with',
    // 'accept': 'application/json',

    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json'

}


export default axios;